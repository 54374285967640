
.CodeUpload {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  & > button {
    max-width: 15rem !important;
  }

  & > p:last-child {
    margin-top: 10px;
    font-weight: bold;
  }
}