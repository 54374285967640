html,
body {
	font-family: "Roboto Mono", sans-serif;
	// font-size: $font-s;
	padding: 0;
	margin: 0;
	height: 100%;
	max-height: 100%;
	display: flex;
	flex-direction: column;
}

#app {
	height: 100%;
	max-height: 100%;
	user-select: none;
	display: flex;
	flex-direction: column;
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}

a,
a:hover,
a:visited,
a:active {
	color: inherit;
	text-decoration: none; /* no underline */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

* {
	-webkit-tap-highlight-color: transparent; /* for removing the highlight */
}

svg {
	.fa-secondary {
		opacity: 0.5;
	}
}
