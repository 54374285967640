$color-black: #111111;
$color-white: #ffffff;

$color-gray-dark: #686b6e;
$color-gray: #a8b1bd;
$color-gray-light: #c4c4c4;
$color-gray-extra-light: #e9eaec;

$color-blue: #0566eb;
$color-red: #f43467;
$color-yellow: #ffd740;

$color-main-brand: #29deb5;

$color-primary: $color-main-brand;
$color-primary-medium: lighten($color-main-brand, 10%);
$color-primary-light: lighten($color-main-brand, 20%);
$color-primary-extra-light: lighten($color-main-brand, 40%);
