@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;400;500;700&display=swap);
body{margin:0;font-family:'Roboto Mono', monospace;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}

.PageLayout_PageLayout__3y9UL{display:grid;width:100vw;height:100vh;grid-template-columns:1fr;grid-template-rows:4rem 1fr 4.5rem;grid-template-areas:'header' 'content' 'footer'}.PageLayout_MenuLayout__YbB-L{grid-area:header}.PageLayout_FooterLayout__1-cYf{grid-area:footer;margin-bottom:1rem}.PageLayout_FooterLineContainer__2tX3o{display:flex;flex-wrap:wrap;flex-direction:row;padding-left:1rem;padding-bottom:1rem}.PageLayout_FooterLineContainer__2tX3o a{color:#4183C4 !important;text-decoration:underline}.PageLayout_FooterLineContainer__2tX3o>span{margin-right:10px}.PageLayout_ContentLayout__1kfzX{padding:1rem;grid-area:content}.PageLayout_SmallerSizeFooter__16Poq{flex-direction:column}.PageLayout_SmallUsername__3B3hS{padding-left:5px !important;padding-right:5px !important;font-style:italic}

.Logo_Logo__3T0RZ{padding:0;font-size:1.2rem}.Logo_Logo__3T0RZ>span{color:#29deb5;font-weight:bold}.Logo_small__2zpWJ{font-size:1rem}

.Markdown_Markdown__2o5Na pre code{border-radius:3px;background-color:#111111;color:white;display:block;white-space:pre;max-width:100%;margin-right:1rem;min-width:100px;padding:2px}.Markdown_Markdown__2o5Na a{color:#4183C4 !important;text-decoration:underline}

.LoginPage_LoginPage__h1rDJ{display:flex;flex-direction:column}.LoginPage_LoginPage__h1rDJ>span{margin-bottom:1rem}.LoginPage_CompleteProfileForm__20OYh{margin-top:3rem}.LoginPage_LoginButtonsWrapper__3ZbO6{display:flex;flex-direction:row}.LoginPage_LoginButtonsWrapper__3ZbO6>*:first-child{margin-right:2rem}.LoginPage_LoginButtonsWrapperSmall__3sQys{flex-direction:column}.LoginPage_LoginButtonsWrapperSmall__3sQys>*{margin-bottom:20px}

.CompleteProfileForm_Form__1Cjvu{display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start}.CompleteProfileForm_Form__1Cjvu>*{margin-bottom:2rem}.CompleteProfileForm_Form__1Cjvu a{color:#4183C4 !important;text-decoration:underline}

.FieldTextInput_FieldTextInput__NXjOi{display:flex;flex-direction:column}

.CustomToast_Message__3e1Bp{font-family:"Roboto Mono", sans-serif}

.GoogleLoginButton_GoogleLoginButton__1dfyp{width:16rem}


.TeamPage_TeamPage__3uz1a{max-width:100vw}.TeamPage_TeamPageContainer__1z4Ju{display:flex;flex-direction:column}

.TeamDetailsView_ViewJoinCode__3se_i{display:flex;flex-direction:row;align-items:center;margin:0.5rem 0}.TeamDetailsView_ViewJoinCode__3se_i>label{font-weight:bold;margin-right:1rem}.TeamDetailsView_ViewJoinCode__3se_i>div>input{border-radius:.28571rem 0 0 .28571rem !important}.TeamDetailsView_ViewJoinCode__3se_i>button{border-radius:0 .28571rem .28571rem 0 !important;height:40px}.TeamDetailsView_DangerZone__3R-gw{margin:0 0 2rem 0 !important}

.TeamMemberList_TeamMemberList__3tziw{margin-top:2rem;display:flex;flex-direction:column;margin-bottom:2rem}.TeamMemberList_TeamMemberListTitle__XWQWI{margin-bottom:0 !important}

.LeaveTeam_LeaveTeam__IWRNA{max-width:30rem;display:flex;flex-direction:column}.LeaveTeam_LeaveTeam__IWRNA>label{margin-top:2rem;margin-bottom:0.4rem}

.JoinOrCreateTeamView_JoinOrCreateTeamViewTitle__IsQDK{margin-bottom:2rem}

.JoinTeam_JoinTeam__2kzbN{display:flex;flex-direction:column;margin-bottom:2rem}.JoinTeam_JoinTeamForm__18iE3{max-width:15rem !important}

.CreateTeam_CreateTeam__bDi3H{display:flex;flex-direction:column;margin-top:2rem}.CreateTeam_CreateTeamForm__VY0OE{display:flex;flex-direction:column}.CreateTeam_CreateTeamForm__VY0OE>button{margin-top:1.3rem !important}.CreateTeam_FormField__1rj2z{max-width:15rem !important}

.PageLoader_PageLoader__1GP9n{display:flex;flex-direction:row;align-items:center}.PageLoader_PageLoader__1GP9n>div:last-child{margin-left:1rem}

.ViewAndRefreshApiToken_ViewAndRefreshApiToken__29feT{display:flex;flex-direction:column;margin-top:2rem}.ViewAndRefreshApiToken_ControlRow__apjAz{display:flex;flex-direction:row;align-items:center}.ViewAndRefreshApiToken_ControlRow__apjAz>button{margin-left:0.4rem !important;max-width:10rem !important}

.ApiGuidePage_MarkdownContentWrapper__UDwYF{margin-top:2rem}

.AvailableProblemsList_AvailableProblemsList__2m79S{display:flex;flex-direction:column}.AvailableProblemsList_AvailableProblemsList__2m79S>div{margin-left:3rem !important}.AvailableProblemsList_LinkListItemText__25Sfn{margin-left:0.6rem;color:#111111}

.ProblemDetailsPage_ProblemDetailsPage__1qIbF{display:flex;flex-direction:column}.ProblemDetailsPage_ToSolveDisclaimer__2cj4D{margin-top:2rem}.ProblemDetailsPage_ToSolveDisclaimer__2cj4D>a{color:#4183C4 !important}

.SubmissionsList_SubmissionsList__1k4ih{margin-top:2rem}

.CodeUpload_CodeUpload__eeB4b{margin-top:2rem;display:flex;flex-direction:column}.CodeUpload_CodeUpload__eeB4b>button{max-width:15rem !important}.CodeUpload_CodeUpload__eeB4b>p:last-child{margin-top:10px;font-weight:bold}

.IndividualLeaderboardPage_IndividualLeaderboardPage__33ZtT>h3{margin-bottom:2rem !important}.IndividualLeaderboardPage_IndividualLeaderboardPage__33ZtT>table{max-width:50rem !important}

.TeamLeaderboardPage_TeamLeaderboardPage__3-GCZ>h3{margin-bottom:2rem !important}.TeamLeaderboardPage_TeamLeaderboardPage__3-GCZ>table{max-width:50rem !important}

.TermsOfUseAndPrivacyPolicy_TermsOfUseAndPrivacyPolicy__3cEKq h1,.TermsOfUseAndPrivacyPolicy_TermsOfUseAndPrivacyPolicy__3cEKq h2{margin:15px 0}

html,body{font-family:"Roboto Mono", sans-serif;padding:0;margin:0;height:100%;max-height:100%;display:flex;flex-direction:column}#app{height:100%;max-height:100%;-webkit-user-select:none;user-select:none;display:flex;flex-direction:column;margin:0}h1,h2,h3,h4,h5,h6{margin:0;padding:0}a,a:hover,a:visited,a:active{color:inherit;text-decoration:none}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}input[type="number"]{-moz-appearance:textfield}*{-webkit-tap-highlight-color:transparent}svg .fa-secondary{opacity:0.5}

