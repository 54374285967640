
.ProblemDetailsPage {
  display: flex;
  flex-direction: column;
}

.ToSolveDisclaimer {
  margin-top: 2rem;

  & > a {
    color: #4183C4 !important;
  }
}
