@import "../../../styles/_colors";

.Logo {
  padding: 0;
  font-size: 1.2rem;

  & > span {
    color: $color-main-brand;
    font-weight: bold;
  }
}

.small {
  font-size: 1rem;
}