@import "src/styles/globals";

.TeamPage {
	max-width: 100vw;
}

.TeamPageContainer {
	display: flex;
	flex-direction: column;
}
