
.CreateTeam {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.CreateTeamForm {
  display: flex;
  flex-direction: column;

  & > button {
    margin-top: 1.3rem !important;
  }
}

.FormField {
  max-width: 15rem !important;
}