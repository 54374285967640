
.TeamMemberList {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.TeamMemberListTitle {
  margin-bottom: 0 !important;
}