
.Markdown {
  & pre code {
    border-radius: 3px;
    background-color: #111111;
    color: white;
    display: block;
    white-space: pre;
    max-width: 100%;
    margin-right: 1rem;
    min-width: 100px;
    padding: 2px;
  }

  & a {
    color: #4183C4 !important;
    text-decoration: underline;
  }
}