
.PageLayout {
  display: grid;
  width: 100vw;
  height: 100vh;

  grid-template-columns: 1fr;
  grid-template-rows:4rem 1fr 4.5rem;
  grid-template-areas: 'header'
    'content'
    'footer';
}

.MenuLayout {
  grid-area: header;
}

.FooterLayout {
  grid-area: footer;
  margin-bottom: 1rem;
}

.FooterLineContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-left: 1rem;
  padding-bottom: 1rem;

  & a {
    color: #4183C4 !important;
    text-decoration: underline;
  }

  & > span {
    margin-right: 10px;
  }
}

.ContentLayout {
  padding: 1rem;
  grid-area: content;
}

.SmallerSizeFooter {
  flex-direction: column;
}

.SmallUsername {
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-style: italic;
}