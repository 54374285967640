
.CompleteProfileForm {
}

.Form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > * {
    margin-bottom: 2rem;
  }

  & a {
    color: #4183C4 !important;
    text-decoration: underline;
  }
}
