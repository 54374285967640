@import "src/styles/globals";

.LoginPage {
	display: flex;
	flex-direction: column;

	& > span {
		margin-bottom: 1rem;
	}
}

.CompleteProfileForm {
	margin-top: 3rem;
}

.LoginButtonsWrapper {
	display: flex;
	flex-direction: row;

	& > *:first-child {
		margin-right: 2rem;
	}
}

.LoginButtonsWrapperSmall {
	flex-direction: column;

	& > * {
		margin-bottom: 20px;
	}
}